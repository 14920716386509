<template>
  <div>
    <sidebar homepage="ManageProfile">
      <!-- ======================== Manage Designs ========================= -->
      <sidebar-item
        label="Designs"
        iconClass="fas fa-drafting-compass"
        :to="{ name: 'ManageFloorPlanDesigns' }"
      ></sidebar-item>
      <sidebar-item
        label="Projects"
        iconClass="fas fa-folder"
        :to="{ name: 'Projects' }"
      ></sidebar-item>
      <!-- ======================== Manage Profile ========================= -->
      <sidebar-separator label="Profile"></sidebar-separator>
      <sidebar-item
        label="Profile"
        iconClass="fas fa-user-circle"
        :to="{ name: 'ManageProfile' }"
      ></sidebar-item>
    </sidebar>
  </div>
</template>

<script>
export default {
  components: {
    Sidebar: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/Sidebar.vue"
      ),
    SidebarItem: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarItem.vue"
      ),
    // SidebarDropdown: () =>
    //   import(
    //     "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarDropdown.vue"
    //   ),
    SidebarSeparator: () =>
      import(
        "@/components/GlobalComponents/FdLayout/SidebarComponents/SidebarSeparator.vue"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      //
    }
  }
};
</script>

<style lang="scss" scoped>
</style>